/* stylelint-disable declaration-no-important */
@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

*[class].btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: var(--radius-main);
  padding: 21px 24px;
  width: 100%;
  font-weight: var(--fw-600);
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.02em;
  text-align: center;
  color: var(--color-white);
  background-color: var(--color-green-400);
  transition-property: background-color, color, border-color;
  transition-duration: 0.2s;

  &:hover,
  &:focus {
    border-color: var(--color-green-400);
    background-color: var(--color-white);
  }

  &--reverse {
    color: var(--color-green-400);
    background-color: var(--color-green-200);

    &:hover,
    &:focus {
      border-color: var(--color-green-400);
      color: var(--color-green-400);
      background-color: var(--color-white);
    }
  }

  &--arrow {
    &::after {
      content: "";
      flex-shrink: 0;
      margin-left: 8px;
      width: 20px;
      height: 20px;
      background-image: var(--arrow);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  @include media(desktop) {
    padding: 15px;
  }
}

button.formsapp-popup-button {
  margin: 0 !important;
  border-radius: var(--radius-main) !important;
  padding: 21px 24px !important;
  font-family: var(--font-family-primary) !important;
  font-size: 16px !important;
  white-space: nowrap;
  line-height: 1 !important;
  color: var(--color-white) !important;
  background-color: var(--color-green-400) !important;

  &:hover,
  &:focus {
    border-color: var(--color-green-400) !important;
    color: var(--color-green-400) !important;
    background-color: var(--color-white) !important;

    &::after {
      filter: brightness(0) saturate(100%) invert(48%) sepia(35%) saturate(374%) hue-rotate(126deg) brightness(92%) contrast(89%);
    }
  }
}