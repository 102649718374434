@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.service {
  &__item {
    padding: rem(32);

    &::before {
      content: "";
      display: block;
      margin-bottom: rem(38);
      border-radius: 50%;
      width: 62px;
      height: 62px;
      background-image: url('../images/icons/choices.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-color: var(--color-gray-400);
    }

    p {
      margin-bottom: 0;
    }

    &--choices {
      &::before {
        background-image: url('../images/icons/choices.svg');
      }
    }

    &--calendar {
      &::before {
        background-image: url('../images/icons/calendar.svg');
      }
    }

    &--money {
      &::before {
        background-image: url('../images/icons/money.svg');
      }
    }
  }
}