@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.product {
  overflow: auto;
  min-height: 952px;
  background-position: top right;
  background-size: auto;
  background-repeat: no-repeat;

  &__desc {
    max-width: 600px;
  }

  h2 {
    margin-bottom: 20px;
    font-size: clamp(2rem, 1.3964rem + 2.4762vi, 3.625rem);
    line-height: 1.2;
    text-align: left;
  }

  p,
  span {
    font-size: 22px;
  }

  span {
    font-weight: var(--fw-600);
  }

  ul {
    margin-bottom: rem(52);
    padding-top: rem(55);

    li {
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }

  .btn {
    max-width: 215px;
    color: var(--color-white) !important;
    background-color: var(--color-green-400) !important;

    &--reverse {
      color: var(--color-green-400) !important;
      background-color: #eef3f3 !important;
    }
  }

  @include media(tablet-wide) {
    min-height: 550px;
    background-size: contain;
  }

  @include media(tablet) {
    min-height: 900px;
    background-position: right bottom;
    background-size: 80%;
  }

  @include media(mobile-l) {
    background-size: 100%;
  }
}

section.product {
  padding: rem(112) 0;
}