@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.showcase {
  background-color: #5185810d;

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: rem(74) rem(55);

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    img {
      margin-bottom: rem(36);
    }

    h3 {
      margin-bottom: 30px;
    }

    a {
      font-size: 18px;
    }
  }

  @include media(mobile-l) {
    &__grid {
      grid-template-columns: 1fr;
      gap: rem(30);

      img {
        width: 100%;
        aspect-ratio: 16 / 8;
      }
    }
  }

  @include media(mobile-m) {
    &__grid {
      h3 {
        font-size: 20px;
      }
    }
  }
}

section.showcase {
  padding-bottom: rem(105);

  @include media(mobile-l) {
    padding-bottom: rem(60);
  }
}