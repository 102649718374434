@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 2px solid var(--color-gray-300);
  border-radius: var(--radius-main);
  box-shadow: 0 4px 100px 0 #afadb51a;

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: rem(39) rem(32) rem(38) rem(34);
  }

  h3 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: rem(40);
    font-size: 18px;
  }

  img {
    width: 100%;
  }

  a {
    margin-top: auto;
    width: max-content;
    font-size: 16px;
  }

  @include media(mobile-l) {
    &__content {
      padding: rem(26);
    }

    img {
      aspect-ratio: 16 / 8;
    }
  }
}