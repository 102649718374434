@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.portfolio {
  position: relative;
  padding: rem(110) 0;
  min-height: 955px;
  color: var(--color-white);

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    background-color: var(--color-green-400);
  }

  &__content {
    width: 100%;
    max-width: 689px;
  }

  h2 {
    margin-bottom: 16px;
    font-size: clamp(2rem, 1.5964rem + 2.4762vi, 39rem);
  }

  p {
    margin-bottom: 28px;
    font-size: 22px;
    line-height: 1.45;
  }

  a.btn {
    position: relative;
    z-index: 2;
    max-width: 175px;
    color: var(--color-white);
    background-color: rgba(255, 255, 255, 0.1);
  }

  @include media(tablet) {
    min-height: 650px;
  }

  @include media(mobile-l) {
    padding: rem(60) 0;
    min-height: auto;

    p {
      font-size: 18px;
    }
  }
}