@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.project {
  display: flex;
  align-items: flex-end;
  min-height: 952px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &__desc {
    overflow: hidden;
    margin-bottom: 17px;
    border-radius: var(--radius-main);
    padding: rem(37) rem(35);
    width: 100%;
    max-width: 753px;
    background-color: var(--color-white);
    translate: 18px;

    h2 {
      margin-bottom: 20px;
      width: 80%;
      font-size: clamp(2rem, 1.3964rem + 2.4762vi, 3.625rem);
      line-height: 1.2;
      text-align: left;
      color: var(--color-green-400);
    }

    p {
      font-size: 22px;
    }
  }

  &--reverse {
    .project__desc {
      margin-right: rem(77);
      margin-left: auto;
    }
  }

  @include media(tablet) {
    min-height: 550px;

    &__desc {
      padding: rem(25);

      p {
        font-size: 20px;
      }
    }
  }
}