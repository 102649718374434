@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

h1,
h2,
h3 {
  overflow: hidden;
  width: fit-content;
  font-weight: var(--fw-600);
  line-height: 1.3;
}

h1 {
  font-size: clamp(3rem, 2.015rem + 4vi, 6.1rem);

  @include media(mobile-m) {
    font-size: 47px;
  }

  @include media(mobile-s) {
    font-size: 35px;
  }
}

h2,
.title {
  font-size: clamp(1.5625rem, 1.37rem + 1.2381vi, 2.56rem);
  text-align: center;
}

h3 {
  font-weight: var(--fw-600);
  font-size: 24px;
}

p {
  font-size: 16px;
}