@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

html,
body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  font-style: normal;
  font-size: clamp(14px, 1vw, 18px);
  line-height: 1.5;
  letter-spacing: 0;
  color: var(--color-black);
  background-color: var(--color-white);
  font-optical-sizing: auto;

  &__body {
    min-width: 320px;
    min-height: var(--vh);
  }

  &.open {
    overflow: hidden;
  }

  &--single {
    .header {
      background-color: var(--color-black);
    }
  }
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);

  &--wide {
    max-width: var(--container-width-wide);
  }
}

.site-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
  overflow-x: hidden;
  min-height: var(--vh);
}

.overlay {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    background: var(--gradient);
    inset: 0;
  }
}

.logo {
  display: block;
  margin-right: rem(45);
  width: 100%;
  max-width: 283px;

  img {
    width: 100%;
  }

  @include media(desktop) {
    margin-right: 20px;
    max-width: 250px;
  }

  @include media(mobile-m) {
    max-width: 250px;
  }
}

section {
  &:not(.hero, .portfolio, .contact) {
    padding: rem(92) 0;

    @include media(mobile-m) {
      padding: rem(50) 0;
    }
  }
}

.section-header {
  margin-bottom: rem(66);

  h2 {
    margin: 0 auto 21px;
  }

  p {
    text-align: center;
  }
}

.background {
  background-color: var(--color-gray-400);
}

.link {
  position: relative;
  line-height: 1;
  color: var(--color-green-300);

  &::after {
    content: "";
    position: absolute;
    right: -30px;
    top: -2px;
    width: 20px;
    height: 20px;
    background-image: var(--arrow);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    filter: brightness(0) saturate(100%) invert(51%) sepia(7%) saturate(1792%) hue-rotate(126deg) brightness(93%) contrast(90%);
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: rem(55);

  @include media(tablet) {
    grid-template-columns: repeat(2, 1fr);
    gap: rem(30);

    &[data-grid="3"] {
      li:last-child {
        grid-column: span 2;
      }
    }
  }

  @include media(mobile-l) {
    grid-template-columns: 1fr;

    &[data-grid="3"] {
      li:last-child {
        grid-column: auto;
      }
    }
  }
}