@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  margin: 0 auto;
  padding: 20px 0;
  width: 100%;
  transition-property: background-color, transform, padding;
  transition-duration: 0.15s;

  .formsapp-popup-button {
    margin-left: 20px !important;
    padding: 19px 24px !important;

    @include media(desktop) {
      padding: 10px 15px !important;
    }

    @include media(mobile-m) {
      margin-left: 10px !important;
      padding: 10px !important;
    }
  }

  &.hide {
    transform: translateY(calc(-100% - 30px));
  }

  &.scroll {
    background-color: var(--color-white);

    .nav {
      &__link {
        color: var(--color-green-300);
      }

      .true {
        text-decoration: underline;
      }

      .burger {
        --lines-color: var(--color-black);
      }
    }

    [class].btn--reverse {
      border-color: var(--color-green-300);
      color: var(--color-green-300);
    }
  }

  &.hide.scroll {
    transform: translateY(0);
  }
}