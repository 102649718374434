@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.hero {
  $root: &;

  height: 100dvh;

  &__inner {
    display: flex;

    height: 100%;
    align-items: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__content {
    position: relative;
    z-index: 1;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn {
      max-width: 215px;
      font-size: 21px;
      letter-spacing: 0;
    }

    h1 {
      margin-bottom: 20px;
      letter-spacing: 0.002em;
    }

    p {
      margin-bottom: 40px;
      font-size: 28px;
      letter-spacing: 0;
    }
  }

  @include media(mobile-m) {
    p {
      font-size: 20px;
    }
  }
}