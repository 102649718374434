@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.elevate {
  .section-header {
    margin-bottom: rem(67);
    padding-top: 5px;
  }

  &__grid {
    h3 {
      margin-bottom: 15px;
      color: var(--color-green-400);
    }

    p {
      font-size: 18px;
    }
  }

  &__item {
    position: relative;
    padding-left: rem(62);

    &::before {
      content: "";
      position: absolute;
      left: 0;
      display: block;
      width: 32px;
      height: 32px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--quality {
      &::before {
        background-image: url("../images/icons/quality.svg");
      }
    }

    &--integrity {
      &::before {
        background-image: url("../images/icons/integrity.svg");
      }
    }

    &--customer {
      &::before {
        background-image: url("../images/icons/customer.svg");
      }
    }
  }
}

section.elevate {
  padding-bottom: rem(105);
}