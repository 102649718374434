@import "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap";
:root {
  --content-width: 1240px;
  --content-width-wide: 1340px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --container-width-wide: calc(var(--content-width-wide)  + (var(--container-offset) * 2));
  --font-family-primary: "Inter", sans-serif;
  --fw-400: 400;
  --fw-600: 600;
  --color-white: #fff;
  --color-black: #2d2d2d;
  --color-green-100: #e8eef3;
  --color-green-200: #5185811a;
  --color-green-300: #009379;
  --color-green-400: #518581;
  --color-green-900: #121c1b;
  --color-orange-400: #ffb23f;
  --color-gray-300: #f3f3f3;
  --color-gray-400: #f6f9f8;
  --radius-main: 8px;
  --gradient: linear-gradient(180deg, #fff0 0%, #fff 100%, #fff 100.01%);
  --arrow: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.125 10H16.875' stroke='%23F8F9FF' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.25 4.375L16.875 10L11.25 15.625' stroke='%23F8F9FF' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  letter-spacing: 0;
  color: var(--color-black);
  background-color: var(--color-white);
  font-optical-sizing: auto;
  font-size: clamp(14px, 1vw, 18px);
  font-style: normal;
  line-height: 1.5;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
}

.page.open {
  overflow: hidden;
}

.page--single .header {
  background-color: var(--color-black);
}

.container {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.container--wide {
  max-width: var(--container-width-wide);
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
  overflow-x: hidden;
}

.overlay {
  position: relative;
}

.overlay:before {
  content: "";
  z-index: 1;
  background: var(--gradient);
  position: absolute;
  inset: 0;
}

.logo {
  width: 100%;
  max-width: 283px;
  margin-right: 2.8125rem;
  display: block;
}

.logo img {
  width: 100%;
}

@media only screen and (max-width: 1280px) {
  .logo {
    max-width: 250px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 576px) {
  .logo {
    max-width: 250px;
  }
}

section:not(.hero, .portfolio, .contact) {
  padding: 5.75rem 0;
}

@media only screen and (max-width: 576px) {
  section:not(.hero, .portfolio, .contact) {
    padding: 3.125rem 0;
  }
}

.section-header {
  margin-bottom: 4.125rem;
}

.section-header h2 {
  margin: 0 auto 21px;
}

.section-header p {
  text-align: center;
}

.background {
  background-color: var(--color-gray-400);
}

.link {
  color: var(--color-green-300);
  line-height: 1;
  position: relative;
}

.link:after {
  content: "";
  width: 20px;
  height: 20px;
  background-image: var(--arrow);
  filter: brightness(0) saturate() invert(51%) sepia(7%) saturate(1792%) hue-rotate(126deg) brightness(93%) contrast(90%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: -2px;
  right: -30px;
}

.grid {
  grid-template-columns: repeat(3, 1fr);
  gap: 3.4375rem;
  display: grid;
}

@media only screen and (max-width: 992px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.875rem;
  }

  .grid[data-grid="3"] li:last-child {
    grid-column: span 2;
  }
}

@media only screen and (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
  }

  .grid[data-grid="3"] li:last-child {
    grid-column: auto;
  }
}

.hidden {
  display: none !important;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3 {
  width: -moz-fit-content;
  width: fit-content;
  font-weight: var(--fw-600);
  line-height: 1.3;
  overflow: hidden;
}

h1 {
  font-size: clamp(3rem, 2.015rem + 4vi, 6.1rem);
}

@media only screen and (max-width: 576px) {
  h1 {
    font-size: 47px;
  }
}

@media only screen and (max-width: 475px) {
  h1 {
    font-size: 35px;
  }
}

h2, .title {
  text-align: center;
  font-size: clamp(1.5625rem, 1.37rem + 1.2381vi, 2.56rem);
}

h3 {
  font-weight: var(--fw-600);
  font-size: 24px;
}

p {
  font-size: 16px;
}

.header {
  z-index: 10;
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
  transition-property: background-color, transform, padding;
  transition-duration: .15s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header .formsapp-popup-button {
  margin-left: 20px !important;
  padding: 19px 24px !important;
}

@media only screen and (max-width: 1280px) {
  .header .formsapp-popup-button {
    padding: 10px 15px !important;
  }
}

@media only screen and (max-width: 576px) {
  .header .formsapp-popup-button {
    margin-left: 10px !important;
    padding: 10px !important;
  }
}

.header.hide {
  transform: translateY(calc(-100% - 30px));
}

.header.scroll {
  background-color: var(--color-white);
}

.header.scroll .nav__link {
  color: var(--color-green-300);
}

.header.scroll .nav .true {
  text-decoration: underline;
}

.header.scroll .nav .burger {
  --lines-color: var(--color-black);
}

.header.scroll [class].btn--reverse {
  border-color: var(--color-green-300);
  color: var(--color-green-300);
}

.header.hide.scroll {
  transform: translateY(0);
}

.nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.nav__inner {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .header .nav__inner {
    z-index: -1;
    padding-top: calc(var(--header-height) * 1.5);
    width: 100%;
    height: calc(var(--vh));
    max-width: 340px;
    background-color: var(--color-green-100);
    flex-direction: column;
    justify-content: flex-start;
    padding-inline: 10px;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    top: 0;
    right: 0;
    overflow-y: auto;
    transform: translateX(150%);
  }
}

.nav__list {
  align-items: center;
  margin-right: auto;
  display: flex;
}

@media only screen and (max-width: 1280px) {
  .header .nav__list {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 992px) {
  .header .nav__list {
    z-index: -1;
    flex-direction: column;
  }
}

.nav__item {
  margin-right: 12px;
}

@media only screen and (max-width: 992px) {
  .header .nav__item {
    margin-bottom: 30px;
  }
}

.nav__link {
  white-space: nowrap;
  text-transform: capitalize;
  color: var(--color-green-900);
  padding: 10px;
  font-size: 16px;
  transition: color .2s;
}

.nav__link:hover, .nav__link:focus, .nav__link.true {
  color: var(--color-green-400);
}

.nav__btns {
  max-width: 415px;
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

.nav__btns button.formsapp-popup-button {
  min-width: 242px;
}

@media only screen and (max-width: 1280px) {
  .nav__btns button.formsapp-popup-button {
    min-width: 200px;
  }
}

@media only screen and (max-width: 768px) {
  .nav__btns button.formsapp-popup-button {
    min-width: 180px;
  }
}

@media only screen and (max-width: 1280px) {
  .nav__btns .btn {
    max-width: 120px;
  }
}

@media only screen and (max-width: 992px) {
  .nav__btns {
    margin: 0 auto;
  }

  .header .nav__list {
    margin-right: 0;
  }

  .nav__btns {
    justify-content: center;
    margin: 0 auto;
  }

  .nav--footer {
    flex-direction: column;
  }
}

[class].btn {
  border-radius: var(--radius-main);
  width: 100%;
  font-weight: var(--fw-600);
  letter-spacing: .02em;
  text-align: center;
  color: var(--color-white);
  background-color: var(--color-green-400);
  border: 1px solid #0000;
  justify-content: center;
  align-items: center;
  padding: 21px 24px;
  font-size: 16px;
  line-height: 1;
  transition-property: background-color, color, border-color;
  transition-duration: .2s;
  display: flex;
  position: relative;
}

[class].btn:hover, [class].btn:focus {
  border-color: var(--color-green-400);
  background-color: var(--color-white);
}

[class].btn--reverse {
  color: var(--color-green-400);
  background-color: var(--color-green-200);
}

[class].btn--reverse:hover, [class].btn--reverse:focus {
  border-color: var(--color-green-400);
  color: var(--color-green-400);
  background-color: var(--color-white);
}

[class].btn--arrow:after {
  content: "";
  width: 20px;
  height: 20px;
  background-image: var(--arrow);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
  margin-left: 8px;
}

@media only screen and (max-width: 1280px) {
  [class].btn {
    padding: 15px;
  }
}

button.formsapp-popup-button {
  white-space: nowrap;
  border-radius: var(--radius-main) !important;
  font-family: var(--font-family-primary) !important;
  color: var(--color-white) !important;
  background-color: var(--color-green-400) !important;
  margin: 0 !important;
  padding: 21px 24px !important;
  font-size: 16px !important;
  line-height: 1 !important;
}

button.formsapp-popup-button:hover, button.formsapp-popup-button:focus {
  border-color: var(--color-green-400) !important;
  color: var(--color-green-400) !important;
  background-color: var(--color-white) !important;
}

button.formsapp-popup-button:hover:after, button.formsapp-popup-button:focus:after {
  filter: brightness(0) saturate() invert(48%) sepia(35%) saturate(374%) hue-rotate(126deg) brightness(92%) contrast(89%);
}

.burger {
  --line-height: 2px;
  --buger-size: 20px;
  --move: calc(var(--buger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-green-400);
  width: calc(var(--buger-size)  + 5px);
  height: var(--buger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .1s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.policy .container {
  padding: 4.375rem 15px;
}

.policy__content {
  margin-bottom: 3rem;
}

.policy h1 {
  color: var(--color-green-400);
  margin: 0 auto 2.5rem;
  position: relative;
}

.policy h2 {
  font-size: 30px;
  font-weight: var(--fw-600);
  text-align: left;
  color: var(--color-green-400);
  margin-bottom: 24px;
  line-height: 1.3;
}

.policy ul {
  margin-bottom: 24px;
  padding-left: 15px;
}

.policy li {
  padding-left: 5px;
  font-size: 20px;
  line-height: 1.43;
  position: relative;
}

.policy li:before {
  content: "✓";
  position: absolute;
  left: -18px;
}

.policy li:not(:last-child) {
  margin-bottom: 3px;
}

.policy p {
  font-size: 18px;
  line-height: 1.2;
}

.policy p:not(:last-child) {
  margin-bottom: 30px;
}

.policy a {
  text-decoration: underline;
}

.policy b, .policy strong {
  font-weight: var(--fw-600);
}

@media only screen and (max-width: 768px) {
  .policy .container {
    padding: 40px 0;
  }

  .policy h1 {
    text-align: center;
    margin-bottom: 30px;
  }
}

.footer {
  background-color: var(--color-green-900);
  padding: 2.75rem 0 0;
}

.footer__mail {
  color: var(--color-white);
  font-size: 20px;
  display: block;
  translate: 0 12px;
}

.footer__top {
  margin-bottom: 4.625rem;
}

.footer__bottom {
  border-top: 1px solid #fff3;
  padding: 1.5625rem 0;
}

.footer__bottom * {
  color: var(--color-white);
}

.footer__bottom .container {
  justify-content: space-between;
  display: flex;
}

.footer__bottom li:not(:last-child) {
  margin-right: 3.75rem;
}

.footer__bottom li:last-child {
  margin-right: 0;
}

.footer__bottom a {
  font-weight: var(--fw-600);
  padding: 0;
  font-size: 16px;
}

.footer .logo {
  translate: 0 22px;
}

.footer .nav__btns {
  max-width: 415px;
  justify-content: space-between;
}

@media only screen and (max-width: 992px) {
  .footer .nav__btns {
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 768px) {
  .footer .nav__btns {
    justify-content: center;
  }
}

@media only screen and (max-width: 576px) {
  .footer .nav__btns {
    flex-direction: column;
    align-items: center;
  }
}

.footer .nav .btn {
  max-width: 153px;
  color: var(--color-white);
  margin-right: 20px;
}

@media only screen and (max-width: 576px) {
  .footer .nav .btn {
    margin-bottom: 20px;
    margin-right: 0;
  }
}

.footer .container--wide {
  padding-inline-start: 20px;
}

.footer .copyright {
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  .footer__mail {
    text-align: center;
  }

  .footer__mail, .footer .logo {
    margin-bottom: 20px;
    translate: 0;
  }

  .footer__bottom .container--wide {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .footer .copyright {
    text-align: center;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 576px) {
  .footer__mail {
    font-size: 16px;
  }
}

.hero {
  height: 100dvh;
}

.hero__inner {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  display: flex;
}

.hero__content {
  z-index: 1;
  width: 100%;
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.hero__content .btn {
  max-width: 215px;
  letter-spacing: 0;
  font-size: 21px;
}

.hero__content h1 {
  letter-spacing: .002em;
  margin-bottom: 20px;
}

.hero__content p {
  letter-spacing: 0;
  margin-bottom: 40px;
  font-size: 28px;
}

@media only screen and (max-width: 576px) {
  .hero p {
    font-size: 20px;
  }
}

.contact {
  padding: 5.3125rem 0 5.8125rem;
}

.contact__form {
  width: 100%;
  max-width: 570px;
  margin: 0 auto 3.125rem;
}

.contact__form-box {
  display: flex;
}

.contact__form-box label {
  width: 100%;
  margin-bottom: 0;
  margin-right: 24px;
}

.contact__footer {
  text-align: center;
}

.contact__footer p {
  letter-spacing: .02em;
  text-transform: uppercase;
  color: var(--color-green-900);
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.86;
}

.contact__footer a {
  font-size: 16px;
}

.contact h2 {
  margin-bottom: 34px;
}

.contact label {
  display: block;
}

.contact input, .contact textarea {
  border-radius: var(--radius-main);
  width: 100%;
  color: #bdbdbd;
  background-color: #0000;
  border: 1px solid #dcca8752;
  padding: 15px 24px;
}

.contact input::placeholder, .contact textarea::placeholder {
  color: #bdbdbd;
}

.contact textarea {
  min-height: 170px;
  resize: none;
  margin-bottom: 24px;
}

.contact .btn {
  max-width: 177px;
  padding-block: 10px !important;
}

.contact .section-header {
  margin-bottom: 3.625rem;
}

@media only screen and (max-width: 768px) {
  .contact {
    padding: 3.75rem 0;
  }

  .contact__form-box {
    flex-direction: column;
  }

  .contact__form-box label {
    margin-bottom: 24px;
    margin-right: 0;
  }

  .contact__form-box .btn {
    width: 100%;
    max-width: 100%;
  }
}

.card {
  border: 2px solid var(--color-gray-300);
  border-radius: var(--radius-main);
  flex-direction: column;
  display: flex;
  overflow: hidden;
  box-shadow: 0 4px 100px #afadb51a;
}

.card__content {
  height: 100%;
  flex-direction: column;
  padding: 2.4375rem 2rem 2.375rem 2.125rem;
  display: flex;
}

.card h3 {
  margin-bottom: 10px;
}

.card p {
  margin-bottom: 2.5rem;
  font-size: 18px;
}

.card img {
  width: 100%;
}

.card a {
  width: max-content;
  margin-top: auto;
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  .card__content {
    padding: 1.625rem;
  }

  .card img {
    aspect-ratio: 16 / 8;
  }
}

.project {
  min-height: 952px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: flex-end;
  display: flex;
}

.project__desc {
  border-radius: var(--radius-main);
  width: 100%;
  max-width: 753px;
  background-color: var(--color-white);
  margin-bottom: 17px;
  padding: 2.3125rem 2.1875rem;
  overflow: hidden;
  translate: 18px;
}

.project__desc h2 {
  width: 80%;
  text-align: left;
  color: var(--color-green-400);
  margin-bottom: 20px;
  font-size: clamp(2rem, 1.3964rem + 2.4762vi, 3.625rem);
  line-height: 1.2;
}

.project__desc p {
  font-size: 22px;
}

.project--reverse .project__desc {
  margin-left: auto;
  margin-right: 4.8125rem;
}

@media only screen and (max-width: 992px) {
  .project {
    min-height: 550px;
  }

  .project__desc {
    padding: 1.5625rem;
  }

  .project__desc p {
    font-size: 20px;
  }
}

.product {
  min-height: 952px;
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: auto;
  overflow: auto;
}

.product__desc {
  max-width: 600px;
}

.product h2 {
  text-align: left;
  margin-bottom: 20px;
  font-size: clamp(2rem, 1.3964rem + 2.4762vi, 3.625rem);
  line-height: 1.2;
}

.product p, .product span {
  font-size: 22px;
}

.product span {
  font-weight: var(--fw-600);
}

.product ul {
  margin-bottom: 3.25rem;
  padding-top: 3.4375rem;
}

.product ul li:not(:last-child) {
  margin-bottom: 30px;
}

.product .btn {
  max-width: 215px;
  color: var(--color-white) !important;
  background-color: var(--color-green-400) !important;
}

.product .btn--reverse {
  color: var(--color-green-400) !important;
  background-color: #eef3f3 !important;
}

@media only screen and (max-width: 1180px) {
  .product {
    min-height: 550px;
    background-size: contain;
  }
}

@media only screen and (max-width: 992px) {
  .product {
    min-height: 900px;
    background-position: 100% 100%;
    background-size: 80%;
  }
}

@media only screen and (max-width: 768px) {
  .product {
    background-size: 100%;
  }
}

section.product {
  padding: 7rem 0;
}

.solutions__grid li {
  counter-increment: solution;
  padding: 1.6875rem 2.0625rem 2.5rem;
}

.solutions__grid li:before {
  content: counter(solution, decimal-leading-zero);
  font-weight: var(--fw-600);
  color: var(--color-dark-400);
  margin-bottom: 1.875rem;
  font-size: 63px;
  display: inline-block;
}

@media only screen and (max-width: 576px) {
  .solutions__grid li {
    padding: 20px;
  }

  .solutions__grid li:before {
    margin-bottom: 10px;
    font-size: 50px;
  }
}

.portfolio {
  min-height: 955px;
  color: var(--color-white);
  padding: 6.875rem 0;
  position: relative;
}

.portfolio:before {
  content: "";
  z-index: -1;
  background-color: var(--color-green-400);
  position: absolute;
  inset: 0;
}

.portfolio__content {
  width: 100%;
  max-width: 689px;
}

.portfolio h2 {
  margin-bottom: 16px;
  font-size: clamp(2rem, 1.5964rem + 2.4762vi, 39rem);
}

.portfolio p {
  margin-bottom: 28px;
  font-size: 22px;
  line-height: 1.45;
}

.portfolio a.btn {
  z-index: 2;
  max-width: 175px;
  color: var(--color-white);
  background-color: #ffffff1a;
  position: relative;
}

@media only screen and (max-width: 992px) {
  .portfolio {
    min-height: 650px;
  }
}

@media only screen and (max-width: 768px) {
  .portfolio {
    min-height: auto;
    padding: 3.75rem 0;
  }

  .portfolio p {
    font-size: 18px;
  }
}

.catalog__grid {
  gap: 3.3125rem 3.4375rem;
  padding-bottom: 6px;
}

@media only screen and (max-width: 992px) {
  .catalog__grid {
    gap: 1.875rem;
    padding-bottom: 0;
  }
}

.service__item {
  padding: 2rem;
}

.service__item:before {
  content: "";
  width: 62px;
  height: 62px;
  background-image: url("../images/icons/choices.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--color-gray-400);
  border-radius: 50%;
  margin-bottom: 2.375rem;
  display: block;
}

.service__item p {
  margin-bottom: 0;
}

.service__item--choices:before {
  background-image: url("../images/icons/choices.svg");
}

.service__item--calendar:before {
  background-image: url("../images/icons/calendar.svg");
}

.service__item--money:before {
  background-image: url("../images/icons/money.svg");
}

.client__list {
  justify-content: space-between;
  display: flex;
}

.client__list li {
  max-width: 595px;
  align-items: flex-start;
  display: flex;
}

.client__list li:first-child {
  margin-right: 20px;
}

.client__list img {
  object-fit: contain;
  margin-right: 2.1875rem;
}

.client__list p {
  margin-bottom: 17px;
  font-size: 18px;
  line-height: 1.55;
}

.client__list span {
  letter-spacing: .04em;
  text-transform: uppercase;
  color: var(--color-green-400);
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
}

.client .section-header {
  margin-bottom: 4.375rem;
}

@media only screen and (max-width: 768px) {
  .client__list, .client__list li {
    flex-direction: column;
  }

  .client__list li:first-child, .client__list img {
    margin-bottom: 30px;
    margin-right: 0;
  }
}

.needs__grid {
  grid-template-columns: repeat(2, 1fr);
  gap: 1.875rem 2.5rem;
  display: grid;
}

.needs__grid li {
  border: 2px solid var(--color-gray-300);
  border-radius: var(--radius-main);
  flex-direction: column;
  align-items: center;
  padding: 2.875rem 2.875rem 0;
  display: flex;
  overflow: hidden;
  box-shadow: 0 4px 100px #afadb51a;
}

.needs__grid h3 {
  margin-bottom: 14px;
}

.needs__grid p {
  margin-bottom: 3.5rem;
  font-size: 18px;
}

.needs__grid img {
  border-radius: var(--radius-main);
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .needs__grid {
    grid-template-columns: 1fr;
    gap: 1.875rem;
  }

  .needs__grid li {
    padding: 1.875rem 15px;
  }

  .needs__grid p {
    margin-bottom: 20px;
  }

  .needs__grid img {
    width: 100%;
    aspect-ratio: 16 / 8;
  }
}

.elevate .section-header {
  margin-bottom: 4.1875rem;
  padding-top: 5px;
}

.elevate__grid h3 {
  color: var(--color-green-400);
  margin-bottom: 15px;
}

.elevate__grid p {
  font-size: 18px;
}

.elevate__item {
  padding-left: 3.875rem;
  position: relative;
}

.elevate__item:before {
  content: "";
  width: 32px;
  height: 32px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  position: absolute;
  left: 0;
}

.elevate__item--quality:before {
  background-image: url("../images/icons/quality.svg");
}

.elevate__item--integrity:before {
  background-image: url("../images/icons/integrity.svg");
}

.elevate__item--customer:before {
  background-image: url("../images/icons/customer.svg");
}

section.elevate {
  padding-bottom: 6.5625rem;
}

.showcase {
  background-color: #5185810d;
}

.showcase__grid {
  grid-template-columns: repeat(2, 1fr);
  gap: 4.625rem 3.4375rem;
  display: grid;
}

.showcase__grid li {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.showcase__grid img {
  margin-bottom: 2.25rem;
}

.showcase__grid h3 {
  margin-bottom: 30px;
}

.showcase__grid a {
  font-size: 18px;
}

@media only screen and (max-width: 768px) {
  .showcase__grid {
    grid-template-columns: 1fr;
    gap: 1.875rem;
  }

  .showcase__grid img {
    width: 100%;
    aspect-ratio: 16 / 8;
  }
}

@media only screen and (max-width: 576px) {
  .showcase__grid h3 {
    font-size: 20px;
  }
}

section.showcase {
  padding-bottom: 6.5625rem;
}

@media only screen and (max-width: 768px) {
  section.showcase {
    padding-bottom: 3.75rem;
  }
}

/*# sourceMappingURL=main.css.map */
