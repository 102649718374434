@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.client {
  &__list {
    display: flex;
    justify-content: space-between;

    li {
      display: flex;
      align-items: flex-start;
      max-width: 595px;

      &:first-child {
        margin-right: 20px;
      }
    }

    img {
      margin-right: rem(35);
      object-fit: contain;
    }

    p {
      margin-bottom: 17px;
      font-size: 18px;
      line-height: 1.55;
    }

    span {
      display: inline-block;
      font-weight: 700;
      font-size: 16px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: var(--color-green-400);
    }
  }

  .section-header {
    margin-bottom: rem(70);
  }

  @include media(mobile-l) {
    &__list {
      flex-direction: column;

      li {
        flex-direction: column;

        &:first-child {
          margin-right: 0;
          margin-bottom: 30px;
        }
      }

      img {
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
  }
}