@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.contact {
  padding: rem(85) 0 rem(93);

  &__form {
    margin: 0 auto rem(50);
    width: 100%;
    max-width: 570px;
  }

  &__form-box {
    display: flex;

    label {
      margin-right: 24px;
      margin-bottom: 0;
      width: 100%;
    }
  }

  &__footer {
    text-align: center;

    p {
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 16px;
      line-height: 1.86;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: var(--color-green-900);
    }

    a {
      font-size: 16px;
    }
  }

  h2 {
    margin-bottom: 34px;
  }

  label {
    display: block;
  }

  input,
  textarea {
    border: 1px solid rgba(#dcca87, 0.32);
    border-radius: var(--radius-main);
    padding: 15px 24px;
    width: 100%;
    color: #bdbdbd;
    background-color: transparent;

    &::placeholder {
      color: #bdbdbd;
    }
  }

  textarea {
    margin-bottom: 24px;

    min-height: 170px;
    resize: none;
  }

  .btn {
    max-width: 177px;
    padding-block: 10px !important;
  }

  .section-header {
    margin-bottom: rem(58);
  }

  @include media(mobile-l) {
    padding: rem(60) 0;

    &__form-box {
      flex-direction: column;

      label {
        margin-right: 0;
        margin-bottom: 24px;
      }

      .btn {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}