/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root {
  --content-width: 1240px;
  --content-width-wide: 1340px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --container-width-wide: calc(var(--content-width-wide) + (var(--container-offset) * 2));

  // font-family
  --font-family-primary: "Inter", sans-serif;

  // font-weight
  --fw-400: 400;
  --fw-600: 600;

  // color
  --color-white: #fff;
  --color-black: #2d2d2d;
  --color-green-100: #e8eef3;
  --color-green-200: #5185811A;
  --color-green-300: #009379;
  --color-green-400: #518581;
  --color-green-900: #121c1b;
  --color-orange-400: #ffb23f;
  --color-gray-300: #F3F3F3;
  --color-gray-400: #F6F9F8;

  //filter

  //  radius
  --radius-main: 8px;

  // shadow

  // gradient
  --gradient: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%, #FFFFFF 100.01%);



  --arrow: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.125 10H16.875' stroke='%23F8F9FF' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.25 4.375L16.875 10L11.25 15.625' stroke='%23F8F9FF' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}