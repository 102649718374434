@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.solutions {
  &__grid {
    li {
      counter-increment: solution;
      padding: rem(27) rem(33) rem(40);

      &::before {
        content: counter(solution, decimal-leading-zero);
        display: inline-block;
        margin-bottom: rem(30);
        font-weight: var(--fw-600);
        font-size: 63px;
        color: var(--color-dark-400);
      }
    }
  }

  @include media(mobile-m) {
    &__grid {
      li {
        padding: 20px;

        &::before {
          margin-bottom: 10px;
          font-size: 50px;
        }
      }
    }
  }
}