@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.needs {
  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: rem(30) rem(40);

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      border: 2px solid var(--color-gray-300);
      border-radius: var(--radius-main);
      padding: rem(46);
      padding-bottom: 0;
      box-shadow: 0 4px 100px 0 #afadb51a;
    }

    h3 {
      margin-bottom: 14px;
    }

    p {
      margin-bottom: rem(56);
      font-size: 18px;
    }

    img {
      overflow: hidden;
      border-radius: var(--radius-main);
    }
  }

  @include media(mobile-l) {
    &__grid {
      grid-template-columns: 1fr;
      gap: rem(30);

      li {
        padding: rem(30) 15px;
      }

      p {
        margin-bottom: 20px;
      }

      img {
        width: 100%;
        aspect-ratio: 16 / 8;
      }
    }
  }
}