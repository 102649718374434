@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.footer {
  padding: rem(44) 0 0;
  background-color: var(--color-green-900);

  &__mail {
    display: block;
    font-size: 20px;
    color: var(--color-white);
    translate: 0 12px;
  }

  &__top {
    margin-bottom: rem(74);
  }

  &__bottom {
    border-top: 1px solid #FFFFFF33;
    padding: rem(25) 0;

    * {
      color: var(--color-white);
    }

    .container {
      display: flex;
      justify-content: space-between;
    }

    li {
      &:not(:last-child) {
        margin-right: rem(60);
      }

      &:last-child {
        margin-right: 0;
      }
    }

    a {
      padding: 0;
      font-weight: var(--fw-600);
      font-size: 16px;
    }
  }

  .logo {
    translate: 0 22px;
  }

  .nav {
    &__btns {
      justify-content: space-between;
      max-width: 415px;

      @include media(tablet) {
        justify-content: flex-end;
      }

      @include media(mobile-l) {
        justify-content: center;
      }

      @include media(mobile-m) {
        flex-direction: column;
        align-items: center;
      }
    }

    .btn {
      margin-right: 20px;
      max-width: 153px;
      color: var(--color-white);

      @include media(mobile-m) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }

  .container--wide {
    padding-inline-start: 20px;
  }

  .copyright {
    font-size: 16px;
  }

  @include media(mobile-l) {
    &__mail {
      text-align: center;
    }

    &__mail,
    .logo {
      margin-bottom: 20px;
      translate: 0;
    }

    &__bottom {
      .container--wide {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .copyright {
      margin-bottom: 20px;
      text-align: center;
    }
  }

  @include media(mobile-m) {
    &__mail {
      font-size: 16px;
    }
  }
}