@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.catalog {
  &__grid {
    padding-bottom: 6px;
    gap: rem(53) rem(55);
  }

  @include media(tablet) {
    &__grid {
      padding-bottom: 0;
      gap: rem(30);
    }
  }
}